import { isBrowser } from '../../../atoms/root'
import { useAddPsychologistProfileRatingMutation } from './query'
import { useCallback } from 'react'

export const useFormSubmit = (psychologistId) => {
  const [mutate] = useAddPsychologistProfileRatingMutation()
  return useCallback(async (values) => {
    await mutate({
      variables: {
        input: {
          psychologistId,
          rating: Number(values.rating),
          comment: values.comment
        }
      }
    })
    if (isBrowser) {
      localStorage.setItem('profileRated', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
