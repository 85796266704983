import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { CloseButton } from '../../../youtalk-storybook/src/ui'
import { color } from '../../styles/vars/colors'
import { createPortal } from 'react-dom'
import { size } from '../../constants'
import type { EffectCallback, ReactNode } from 'react'

const useOnMount = (effect: EffectCallback) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current === false) {
      mounted.current = true
      effect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

type WithClassName<T = {}> = T & { className?: string }

type NotificationPopupProps = WithClassName<{
  visible: boolean
  key?: React.Key
  hide: () => void
  children?: ReactNode
  container?: HTMLElement
  showCloseButton?: boolean
  isMobile?: boolean
  onOpen?: () => void
}>

const Notification = styled(motion.div)`
  background: #011632;
  padding: 24px;
  width: 480px;
  color: ${color.text.white};
  border-radius: 16px;
  z-index: 10;
  opacity: 1;
  transition: opacity 0.3s ease;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 54px;

  ${CloseButton} {
    color: #c2c2c2;
    position: absolute;
    background: transparent;
    top: 12px;
    right: 12px;
  }

  @media (max-width: ${size.sm}) {
    padding: 24px 20px 64px 20px;
    border-radius: 24px 24px 0px 0px;
    left: 0;
    transform: translateX(0);
    bottom: 0;
    width: calc(100vw - var(--scroll-width));

    ${CloseButton} {
      top: 20px;
      right: 20px;
    }
  }

  @media (max-width: ${size.xs}) {
    padding: 24px 16px 64px 16px;
  }
`

type ExtraProps = { onExitComplete?: () => void; visible: boolean }

function withAnimatePresence<T extends ExtraProps>(
  BaseComponent: React.ComponentType<T>
) {
  function EnhancedComponent(props: T) {
    return (
      <AnimatePresence onExitComplete={props.onExitComplete}>
        {props.visible && <BaseComponent {...props} />}
      </AnimatePresence>
    )
  }

  return styled(EnhancedComponent)``
}

const Popup = styled(
  ({
    className,
    hide,
    children = null,
    container,
    showCloseButton = true,
    onOpen,
    key
  }: NotificationPopupProps) => {
    useOnMount(() => {
      typeof onOpen === 'function' && onOpen()
    })

    return (
      <>
        {createPortal(
          <Notification key={key} className={className}>
            {children}
            {showCloseButton && <CloseButton onClick={hide} />}
          </Notification>,
          container ?? window.document.body
        )}
      </>
    )
  }
)``

export const NotificationPopup = withAnimatePresence<
  NotificationPopupProps & ExtraProps
>(Popup)
