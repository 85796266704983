import React from 'react'
import styled from 'styled-components'
import { Checkbox, Text } from '../../../../youtalk-storybook/src/ui'
import { Field } from 'react-final-form'
import { size } from '../../../constants'

const name = 'rating'

const ratingCheckboxes = [1, 2, 3, 4, 5].map((n) => ({
  text: n.toString(),
  value: n.toString()
}))

const CheckboxGroupRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  height: 40px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 0;
  }
`

const ScoresDecription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 16px;
  color: gray;
`

export const ScoreCheckboxes = () => (
  <>
    <CheckboxGroupRow>
      {ratingCheckboxes.map(({ value, text }) => (
        <Field key={value} name={name} type="radio" value={value}>
          {({ input }) => (
            <Checkbox {...input} id={value} name={name} value={value}>
              {text}
            </Checkbox>
          )}
        </Field>
      ))}
    </CheckboxGroupRow>
    <ScoresDecription>
      <Text.Average>Не информативна</Text.Average>
      <Text.Average>Всё понятно</Text.Average>
    </ScoresDecription>
  </>
)
