/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import { DISABLED_PROPS_MODE, useDisabledProps } from '../hooks/useDisableProps'
import { Field, useFormState } from 'react-final-form'
import { SessionType } from '../../../../atoms/mappers/gqlEnums/sessionType'
import { StyledInputCalendar } from '../MobileView/SelectTimeModal/index.styles'
import { getCurrentMonth } from '../getCurrentDatePeriod'
import { selectorValueToProps } from '../WebView/SelectTimeForm'
import { useExternalState } from './useExternalState'

// eslint-disable-next-line max-lines-per-function
export const InputCalendarField = ({
  psychologistAvailableSlots,
  updateIsUrgent,
  ...props
}) => {
  const { values } = useFormState()
  const [type] = selectorValueToProps(values.sessionTypeAndLanguage)

  const isAsyncChat = type === SessionType.AsyncChat

  const [externalState, setExternalState] = useExternalState(values.date)

  const disabledDates = useMemo(() => {
    if (!externalState) {
      return []
    }
    const month = getCurrentMonth(externalState)

    const mappedSlots = psychologistAvailableSlots.map(
      ({ datetime, isUrgent }) => ({
        text: dayjs(datetime).format('HH:mm'),
        id: datetime,
        value: datetime,
        isUrgent,
        isToday: dayjs(datetime).isSame(dayjs(new Date()), 'days')
      })
    )
    const slotsToFiltered = isAsyncChat
      ? mappedSlots.filter((slot) => slot.isToday === false)
      : mappedSlots

    const enabledDates = slotsToFiltered.reduce((acc, { value }) => {
      acc[dayjs(value).format('YYYY-MM-DD')] = true
      return acc
    }, {})

    return month.filter((day) => !enabledDates[day.format('YYYY-MM-DD')])
  }, [psychologistAvailableSlots, externalState, isAsyncChat])

  const disabledProps = useDisabledProps({
    mode: DISABLED_PROPS_MODE.MONTH,
    disabledDates
  })

  useEffect(() => {
    const dateToCheck = Array.isArray(values.date)
      ? values.date[0]
      : values.date
    if (externalState?.month() !== dateToCheck?.month()) {
      setExternalState(values.date)
    }
  }, [values.time])

  useEffect(() => {
    const dateToCheck = Array.isArray(values.date)
      ? values.date[0]
      : values.date

    if (isAsyncChat) {
      if (dayjs(dateToCheck).day() - dayjs(new Date()).day() === 1) {
        updateIsUrgent(true)
      } else {
        updateIsUrgent(false)
      }
    }
  }, [values.date])

  return (
    <Field name="date">
      {({ input }) => (
        <StyledInputCalendar
          {...input}
          {...props}
          {...disabledProps}
          autoRange={isAsyncChat ? 7 : 1}
          externalState={externalState}
          onMonthChange={setExternalState}
        />
      )}
    </Field>
  )
}
