import React from 'react'
import { ControlTextArea } from '../../../../youtalk-storybook/src/ui'

export const Comment = () => (
  <ControlTextArea
    countCharacters={{
      max: 2000,
      visible: true
    }}
    name="comment"
    placeholder="Добавьте комментарий или поделитесь, что стоит изменить"
  />
)
