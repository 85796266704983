import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Comment } from './Fields/Comment'
import { Form, useFormState } from 'react-final-form'
import { PsychologistProfileRatingModalStep } from './types'
import { ScoreCheckboxes } from './Fields/ScoreCheckboxes'
import { StepHeader } from './common/StepHeader'
import { ThanksForScore } from './ThanksForScore'
import { size } from '../../constants'
import { useChangeStep } from './hooks/useChangeStep'
import { useFormSubmit } from './hooks/useFormSubmit'

const SubmitButton = styled(Button.NewPrimary)``

const AddPsychologistProfileRatingForm = styled(
  ({ className, handleSubmit }) => {
    const { values, submitting } = useFormState()
    const [step, setStep] = useState(
      PsychologistProfileRatingModalStep.SELECT_RATING
    )

    useEffect(() => {
      if (values.rating && values.rating !== '5') {
        setStep(PsychologistProfileRatingModalStep.SELECT_RATING_WITH_COMMENT)
      }
      if (values.rating === '5') {
        handleSubmit()
        setStep(PsychologistProfileRatingModalStep.THANKS_FOR_GOOD_RATING)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])

    return (
      <form className={className} onSubmit={handleSubmit}>
        <ScoreCheckboxes />
        {step ===
          PsychologistProfileRatingModalStep.SELECT_RATING_WITH_COMMENT && (
          <>
            <Comment />
            <SubmitButton loading={submitting} type="submit">
              Отправить
            </SubmitButton>
          </>
        )}
      </form>
    )
  }
)`
  border-radius: 16px;
  padding: 16px;
  margin: 0 -16px -16px -16px;

  ${Button.NewPrimary} {
    margin-top: 8px;
  }

  @media (max-width: ${size.sm}) {
    margin: 0 -12px 0 -12px;
  }

  @media (max-width: ${size.xs}) {
    margin: 0 -8px;
  }
`

const MainForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const initialValues = {
  comment: '',
  step: PsychologistProfileRatingModalStep.SELECT_RATING
}

const Step = ({ psychologistId, handleSubmit }) => {
  const {
    values: { step }
  } = useFormState()

  const stepHeaderProps = useMemo(
    () => ({
      title: <>Насколько понятна и информативна карточка&nbsp;психолога?</>
    }),
    []
  )

  const changeStep = useChangeStep()

  if (step === PsychologistProfileRatingModalStep.THANKS_FOR_GOOD_RATING) {
    return <ThanksForScore />
  }

  const onSubmit = () => {
    handleSubmit()
    changeStep(PsychologistProfileRatingModalStep.THANKS_FOR_GOOD_RATING)
  }

  return (
    <>
      <StepHeader {...stepHeaderProps} />
      <AddPsychologistProfileRatingForm
        handleSubmit={onSubmit}
        psychologistId={psychologistId}
      />
    </>
  )
}

export const AddPsychologistProfileRatingMainForm = ({ psychologistId }) => {
  const submit = useFormSubmit(psychologistId)

  return (
    <MainForm>
      <Form
        initialValues={initialValues}
        onSubmit={submit}
        render={({ handleSubmit }) => (
          <Step handleSubmit={handleSubmit} psychologistId={psychologistId} />
        )}
      />
    </MainForm>
  )
}
