import React from 'react'
import { AddPsychologistProfileRatingMainForm } from './AddPsychologistProfileRatingForm'
import { StyledNotificationPopup } from './index.styles'

export const AddPsychologistProfileRatingMainFormModal = ({
  hide,
  psychologistId,
  visible
}) => (
  <StyledNotificationPopup hide={hide} visible={visible}>
    <AddPsychologistProfileRatingMainForm psychologistId={psychologistId} />
  </StyledNotificationPopup>
)
