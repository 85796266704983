import React, { createContext, useContext, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useGetPsychologistAvailableSlotsQuery } from './query'
import { useInitialValue } from './hooks/useInitialValues'
import { usePsychologistProfileContext } from '../../../pagesTemplates/psychologist'

const FormContext = createContext({})

// eslint-disable-next-line max-lines-per-function
export const AvailableSlotsFormProvider = ({
  children,
  psychologistUserId
}) => {
  const context = usePsychologistProfileContext()
  const [initialDate, setInitialDate] = useState()

  const psychologistID =
    psychologistUserId ??
    context.psychologist?.user?.id ??
    context.psychologist?.userID

  const { data, loading: loadingSlots } = useGetPsychologistAvailableSlotsQuery(
    {
      variables: {
        filter: {
          id: psychologistID
        }
      },
      onCompleted: (data) => {
        const slots = data?.psychologistAvailableSlots?.items ?? []
        const [nearestSlot] = slots

        setInitialDate(dayjs(nearestSlot.datetime))
      }
    }
  )

  const psychologistAvailableSlots = useMemo(
    () => data?.psychologistAvailableSlots?.items ?? [],
    [data?.psychologistAvailableSlots?.items]
  )

  useInitialValue(psychologistAvailableSlots[0])

  return (
    <FormContext.Provider
      value={{
        psychologistAvailableSlots,
        loadingSlots: loadingSlots || !data,
        initialDate
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useAvailableSlotsContext = () => useContext(FormContext)
