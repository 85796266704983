import { CatalogFieldNamesEnum } from '../CatalogContextProvider/enums'
import {
  getCatalogFieldsConfig,
  useWizardFieldsConfig
} from '../WizardScreens/Form/config'
import { isEmpty, pick } from 'rambda'
import { useEffect } from 'react'
import { usePsyCardStorage } from './usePsyCardStorage'

const catalogFieldNames = Object.values(CatalogFieldNamesEnum)

const validateFields = (fields, isWizard, wizardFieldsConfig) =>
  Object.entries(
    isWizard ? wizardFieldsConfig : getCatalogFieldsConfig()
  ).forEach(([fieldName, { schema }]) => schema.validateSync(fields[fieldName]))

const VALIDATE_ERR_MESSAGE = 'validate cache error'

export const useValidateStorageData = (isWizard) => {
  const [storedValue] = usePsyCardStorage()
  const catalogFiltersData = pick(catalogFieldNames, storedValue)
  const wizardFieldsConfig = useWizardFieldsConfig()

  useEffect(() => {
    if (isEmpty(storedValue ?? {})) {
      return
    }
    try {
      validateFields(catalogFiltersData, isWizard, wizardFieldsConfig)
    } catch (error) {
      console.error(VALIDATE_ERR_MESSAGE)
      console.log(error)
      return
    }
  }, [catalogFiltersData])
}
