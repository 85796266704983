/* eslint-disable react-func/max-lines-per-function */
import { CatalogFieldNamesEnum } from '../../CatalogContextProvider/enums'
import { WizardGender } from '../../../atoms/mappers/wizard/enums/gender'
import { WizardHowFeeling } from '../../../atoms/mappers/wizard/enums/howFeeling'
import { WizardLanguage } from '../../../atoms/mappers/wizard/enums/language'
import { WizardPrices } from '../../../atoms/mappers/wizard/enums/prices'
import { WizardStepName, useWizardSteps } from './stepsOrder'
import { WizardTherapyType } from '../../../atoms/mappers/wizard/enums/therapyType'
import { WizardYesNo } from '../../../atoms/mappers/wizard/enums/yesNo'
import { array, boolean, number, string } from 'yup'

const positiveInteger = number().positive().integer()
const language = string().oneOf(Object.values(WizardLanguage))
const therapyType = string().oneOf(Object.values(WizardTherapyType))
const howFeeling = string().oneOf(Object.values(WizardHowFeeling))
const gender = string().oneOf(Object.values(WizardGender))
const preference = string().oneOf(Object.values(WizardYesNo))
const wizardPrices = string().oneOf(Object.values(WizardPrices))

export const useWizardFieldsConfig = () => {
  const { wizardSteps, hasConfigureted } = useWizardSteps()
  return !hasConfigureted
    ? {}
    : wizardSteps.reduce((acc, step, position) => {
        switch (step.name) {
          case WizardStepName.WhoNeedsHelp:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Client]: {
                schema: therapyType.required(),
                step: position
              }
            }
          case WizardStepName.HowFeeling:
            return {
              ...acc,
              [CatalogFieldNamesEnum.HowFeeling]: {
                schema: howFeeling,
                step: position
              }
            }
          case WizardStepName.Acquaintance:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Name]: {
                schema: string().required(),
                step: position
              },
              [CatalogFieldNamesEnum.Age]: {
                schema: positiveInteger.min(0).max(120).required(),
                step: position
              }
            }
          case WizardStepName.WorkAreas:
            return {
              ...acc,
              [CatalogFieldNamesEnum.WorkAreas]: {
                schema: array().of(string()).required(),
                step: position
              }
            }
          case WizardStepName.Friendliness:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Gender]: {
                schema: gender,
                step: position
              },
              [CatalogFieldNamesEnum.PsychologistAge]: {
                schema: array().of(positiveInteger.min(24).max(65)).length(2),
                step: position
              },
              [CatalogFieldNamesEnum.Friendliness]: {
                schema: boolean().required(),
                step: position
              }
            }
          case WizardStepName.Price:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Prices]: {
                schema: array().of(wizardPrices),
                step: position
              }
            }
          case WizardStepName.Preferences:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Preference]: {
                schema: preference.required(),
                step: position
              }
            }
          case WizardStepName.Approaches:
            return {
              ...acc,
              [CatalogFieldNamesEnum.Approaches]: {
                schema: array().of(string()),
                step: position
              }
            }
          default:
            return acc
        }
      }, {})
}

export const getCatalogFieldsConfig = () => ({
  [CatalogFieldNamesEnum.Language]: {
    schema: language.required(),
    step: 1
  },
  [CatalogFieldNamesEnum.Client]: {
    schema: therapyType.required(),
    step: 2
  }
})
