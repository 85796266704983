import styled from 'styled-components'
import { NotificationPopup } from '../../atoms/NotificationPopup'
import { color } from '../../styles/vars/colors'
import { size } from '../../constants'

export const StyledNotificationPopup = styled(NotificationPopup)`
  background-color: ${color.midnight};
  position: absolute;
  right: 100px;
  left: auto !important;

  transform: none !important;

  @media (max-width: ${size.sm}) {
    left: 0px !important;
    padding-bottom: 90px;
    z-index: 1000;
  }
`
