import {
  AbTestsNames,
  InvariantValues,
  useAbTest
} from '../../../hooks/useAbTest'
import { findIndex } from 'rambda'
import { useEffect, useState } from 'react'
import { useViewer } from '../../../queries/useViewer'

/* eslint-disable no-unused-vars */
export enum WizardStepName {
  WizardWelcome = 'WizardWelcome', // 0
  WhoNeedsHelp = 'WhoNeedsHelp', // 1
  HowFeeling = 'HowFeeling', // 2
  Acquaintance = 'Acquaintance', // 3
  WorkAreas = 'WorkAreas', // 4
  AboutHelp = 'AboutHelp', // 5
  Friendliness = 'Friendliness', // 6
  Price = 'Price', // 7
  Preferences = 'Preferences', // 8
  Approaches = 'Approaches' // 9
}
const WizardSteps = [
  {
    name: WizardStepName.WizardWelcome,
    gaEvent: 'wizardWelcome',
    skip: false
  },
  {
    name: WizardStepName.WhoNeedsHelp,
    gaEvent: 'wizardTariff',
    skip: false
  },
  {
    name: WizardStepName.HowFeeling,
    gaEvent: 'wizardWellbeing',
    skip: false
  },
  {
    name: WizardStepName.Acquaintance,
    gaEvent: 'wizardAcquaintance',
    skip: false
  },
  {
    name: WizardStepName.WorkAreas,
    gaEvent: 'wizardRequest',
    skip: false
  },
  {
    name: WizardStepName.AboutHelp,
    gaEvent: 'wizardRequirements',
    skip: false
  },
  {
    name: WizardStepName.Friendliness,
    gaEvent: 'wizardFriendliness',
    skip: false
  },
  {
    name: WizardStepName.Price,
    gaEvent: 'wizardPrice',
    skip: false
  },
  {
    name: WizardStepName.Preferences,
    gaEvent: 'wizardApproachYN',
    skip: false
  },
  {
    name: WizardStepName.Approaches,
    gaEvent: 'wizardApproachList',
    skip: false
  }
]

type TWizardSteps = typeof WizardSteps

const getChangedSteps = (
  steps: TWizardSteps,
  originStep: number,
  newStep: number
) =>
  steps.reduce<TWizardSteps>((result, stepValue, stepPosition, array) => {
    if (
      originStep < newStep &&
      stepPosition >= originStep &&
      stepPosition < newStep
    ) {
      result.push(array[stepPosition + 1])
      return result
    }
    if (
      originStep > newStep &&
      stepPosition <= originStep &&
      stepPosition > newStep
    ) {
      result.push(array[stepPosition - 1])
      return result
    }
    if (stepPosition === newStep) {
      result.push(array[originStep])
      return result
    }
    result.push(stepValue)
    return result
  }, [])

export const useWizardSteps = () => {
  const [wizardSteps, setWizardSteps] = useState(WizardSteps)
  const [hasConfigureted, setHasConfigureted] = useState(false)

  const nameAgeTest = useAbTest(AbTestsNames.NAME_AGE_LAST_STEP)
  const isNameAgeTestYes = nameAgeTest === InvariantValues.YES

  const { data, loading } = useViewer()

  const totalSteps = WizardSteps.length - 1
  const startFrom = findIndex(
    (item) => item.name !== WizardStepName.WizardWelcome && !item.skip,
    wizardSteps
  )

  useEffect(() => {
    if (nameAgeTest && !hasConfigureted && !loading) {
      setWizardSteps((prevSteps) => {
        const steps = prevSteps.map((step) => {
          if (
            data?.viewer?.company?.isHiddenPrice &&
            step.name === WizardStepName.Price
          ) {
            return { ...step, skip: true }
          }
          if (
            data?.viewer?.company?.isHiddenWhoNeedsHelp &&
            step.name === WizardStepName.WhoNeedsHelp
          ) {
            return { ...step, skip: true }
          }
          return step
        })

        if (isNameAgeTestYes) {
          return getChangedSteps(steps, 3, 9)
        }
        return steps
      })

      setHasConfigureted(true)
    }
  }, [isNameAgeTestYes, nameAgeTest, loading, hasConfigureted, data])

  return { wizardSteps, hasConfigureted, totalSteps, startFrom }
}
