import React, { createContext, useCallback, useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { ErrorContextProvider } from '../../hooks/useErrorProvider'
import { SendCodeModal } from '..'
import { usePopup } from '../../../../../youtalk-storybook/src/ui'
import { useRegistrationFormStorage } from '../../hooks/useRegistrationFormStorage'
import { useTranslatedCatalogFiltersData } from '../../hooks/useTranslatedWizardData'

const SendCodeModalContext = createContext({})
const init = { canBeRequestAgain: false, attempt: 0 }

export const SendCodeModalModalContextProvider = ({ children }) => {
  const { visible, show, ...popup } = usePopup()
  const [formData, setFormData] = useRegistrationFormStorage(init)
  const { catalogFiltersData } = formData
  const translatedCatalogFiltersData = useTranslatedCatalogFiltersData(
    formData.catalogFiltersData
  )

  const updateContext = useCallback(
    (values) => {
      setFormData({ ...formData, ...values })
    },
    [formData]
  )
  const resetContext = useCallback(() => {
    setFormData(init)
  }, [])

  useEffect(() => {
    if (dayjs().isAfter(formData.retryAvailableAfter)) {
      resetContext()
    }
  }, [])

  const hide = useCallback(() => {
    resetContext()
    popup.hide()
  })

  const codeProps = {
    onBack: hide,
    onChangePhone: hide
  }

  const open = useCallback((input) => {
    updateContext({
      token: input.token,
      datetime: input.datetime,
      ...input
    })
    show()
  }, [])

  return (
    <SendCodeModalContext.Provider
      value={{
        formData,
        resetContext,
        updateContext,
        codeProps,
        catalogFiltersData,
        modal: { visible, hide, open }
      }}
    >
      <ErrorContextProvider
        allFieldsState={{
          ...formData,
          catalogFiltersData: {
            ...catalogFiltersData,
            translatedCatalogFiltersData
          }
        }}
        hide={hide}
      >
        {children}
        {visible && <SendCodeModal />}
      </ErrorContextProvider>
    </SendCodeModalContext.Provider>
  )
}

export const useSendCodeModalContext = () => useContext(SendCodeModalContext)
