import Cookies from 'js-cookie'
import { FORM_ERROR } from 'final-form'
import { RegistrationSource } from './mappers/gqlEnums/registrationSourse'
import {
  SEND_REGISTRATION_REQUEST,
  sendAdvCakePostback,
  sendCityAdsPostback,
  sendFormDataCarrotQuest,
  sendPostBack,
  sendPostBackAdmitad
} from '../api'
import { formatCatalogData } from './mappers/wizard/utils'
import { getUTM } from './initialQueryParams'
import { omit } from 'rambda'
import {
  pressSendForm,
  submitForm,
  usePsychologistApplication,
  wizardSendForm
} from '../components/GA'
import { toSendDataFormat } from './useFormValues'
import { useErrorModalContext } from '../organisms/RegistrationModals/hooks/useErrorProvider'
import { useMutation } from '@apollo/client'
import { useSendCodeModalContext } from '../organisms/RegistrationModals/CodeSendModal/hooks/useSendCodeModalContext'

export const useFormSubmit = ({ catalogFiltersData, attempt }) => {
  const [sendRegistrationRequest] = useMutation(SEND_REGISTRATION_REQUEST)
  const { onServerError } = useErrorModalContext()
  const context = useSendCodeModalContext()
  usePsychologistApplication()

  // eslint-disable-next-line react-func/max-lines-per-function
  return async (values) => {
    const utm = getUTM()
    const {
      uid,
      utm_source: utmSource,
      click_id: clickId,
      utm_medium: utmMedium,
      utm_term: utmTerm
    } = utm

    pressSendForm('PressSendFormB2C', 'b2c')
    wizardSendForm('wizardPressSendForm', values.psychologistID ?? 'noneChosen')

    const formattedData = toSendDataFormat({
      formData: {
        ...values,
        attempt: attempt ?? 0,
        registrationSource: RegistrationSource.Form,
        promocodeId: values.promocodeSubField?.id,
        userRegistrationPreferences: formatCatalogData({
          ...omit(['name', 'age', 'sourceId'], catalogFiltersData),
          aboutHelp: values.aboutHelp
        })
      },
      utm,
      sourceId: catalogFiltersData?.sourceId ?? values.sourceId,
      pointOfContact: 'ks_subscription_from_wizard'
    })

    try {
      sendFormDataCarrotQuest({
        name: formattedData.name,
        phone: formattedData.phone,
        email: formattedData.email
      })

      const response = await sendRegistrationRequest({
        variables: {
          input: formattedData
        }
      })

      if (response.data.sendRegistrationRequest?.token) {
        submitForm('wizard')
        context.modal.open({
          retryAvailableAfter:
            response.data.sendRegistrationRequest?.retryAvailableAfter,
          token: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          tel: values.tel,
          country: values.countryCode,
          promocodeId: values.promocodeSubField?.id,
          timezone: values.timezone,
          email: values.email,
          attempt: attempt + 1,
          catalogFiltersData: {
            ...catalogFiltersData,
            sourceId: catalogFiltersData?.sourceId ?? values.sourceId,
            age: values.age,
            name: values.name
          }
        })
        const bitrixId = response.data.sendRegistrationRequest?.bitrixLeadId
        wizardSendForm('wizardSendForm', values.psychologistID ?? 'noneChosen')
        if (utmMedium === 'cpa') {
          utmSource === 'advertise' && (await sendPostBack({ bitrixId, uid }))
          if (utmSource === 'network_admitad') {
            const admitad_uid = Cookies.get('admitad_uid')
            sendPostBackAdmitad(bitrixId, admitad_uid)
          }
          if (clickId && utmSource === 'network_cityads') {
            sendCityAdsPostback(bitrixId, clickId, utmTerm)
          }
          if (utmSource === 'advcake') {
            const advcake_track_id = Cookies.get('advcake_track_id')
            const advcake_track_url = Cookies.get('advcake_track_url')
            sendAdvCakePostback(bitrixId, advcake_track_id, advcake_track_url)
          }
        }
      }
    } catch (err) {
      onServerError()
      return { [FORM_ERROR]: 'Failed' }
    }
  }
}
