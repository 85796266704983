import React, { useCallback, useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { ButtonAsLink, Countdown } from '../../../youtalk-storybook/src/ui'
import { Paragraph } from './index.styles'

const isDeadline = (datetime) => dayjs().isAfter(datetime)

export const RequestCodeAgain = styled(
  ({ className, context, requestCode }) => {
    const onComplete = useCallback(() => {
      context.updateContext({ canBeRequestAgain: true })
    }, [])

    const time = useMemo(() => {
      if (!context.formData.retryAvailableAfter) {
        return dayjs()
      }

      return dayjs(context.formData.retryAvailableAfter).toDate()
    }, [
      context.formData.retryAvailableAfter,
      context.formData.canBeRequestAgain
    ])

    const onTick = useCallback(() => {
      if (isDeadline(context.formData.retryAvailableAfter)) {
        onComplete()
      }
    }, [context.formData.retryAvailableAfter])

    if (context.formData.canBeRequestAgain) {
      return (
        <div className={className}>
          <ButtonAsLink onClick={requestCode} type="button">
            Отправить код повторно
          </ButtonAsLink>
        </div>
      )
    }

    return (
      <Paragraph className={className}>
        Запросить код повторно можно через
        <br />
        <Countdown onComplete={onComplete} onTick={onTick} time={time} /> секунд
      </Paragraph>
    )
  }
)`
  width: fit-content;
  text-align: left;
  height: 40px;
`
