/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect } from 'react'
import dayjs from 'dayjs'
import { ErrorContextProvider } from '../../hooks/useErrorProvider'
import { PsychologistProfileProvider } from '../../../../pagesTemplates/psychologist'
import { RegistrationModal } from '..'
import { RegistrationStep } from '../vars'
import { noop } from '../../../../atoms/noop'
import { usePopup } from '../../../../../youtalk-storybook/src/ui'
import { useRegistrationFormStorage } from '../../hooks/useRegistrationFormStorage'
import { useTranslatedCatalogFiltersData } from '../../hooks/useTranslatedWizardData'

const RegistrationModalContext = createContext({})
const init = {
  step: RegistrationStep.IDENTITY,
  canBeRequestAgain: false,
  attempt: 0
}

// eslint-disable-next-line max-lines-per-function
export const RegistrationModalContextProvider = ({
  children,
  catalogFiltersData,
  psychologist,
  isWizard
}) => {
  const { visible, hide, show } = usePopup()
  const [formData, setFormData] = useRegistrationFormStorage(init)

  const translatedCatalogFiltersData =
    useTranslatedCatalogFiltersData(catalogFiltersData)

  const updateContext = useCallback(
    (values) => {
      setFormData({ ...formData, ...values })
    },
    [formData]
  )
  const resetContext = useCallback(() => {
    setFormData(init)
  }, [])

  useEffect(() => {
    if (!visible) {
      resetContext()
    }
    return () => {
      resetContext()
    }
  }, [visible])

  useEffect(() => {
    if (dayjs().isAfter(formData.retryAvailableAfter)) {
      resetContext()
    }
  }, [])

  const stepsProps = {
    [RegistrationStep.IDENTITY]: {
      onBack: noop
    },
    [RegistrationStep.CODE]: {
      onBack: noop,
      onChangePhone: () => updateContext({ step: RegistrationStep.IDENTITY })
    }
  }

  return (
    <PsychologistProfileProvider psychologist={psychologist}>
      <RegistrationModalContext.Provider
        value={{
          formData,
          catalogFiltersData,
          resetContext,
          updateContext,
          stepsProps,
          modal: { visible, hide, show },
          psychologist
        }}
      >
        <ErrorContextProvider
          allFieldsState={{
            ...formData,
            catalogFiltersData: {
              ...catalogFiltersData,
              translatedCatalogFiltersData
            }
          }}
          hide={hide}
        >
          {children}
          {visible && isWizard && (
            <RegistrationModal psychologist={psychologist} />
          )}
        </ErrorContextProvider>
      </RegistrationModalContext.Provider>
    </PsychologistProfileProvider>
  )
}

export const useRegistrationModalContext = () =>
  useContext(RegistrationModalContext)
