import { gql, useQuery } from '@apollo/client'

export const VIEWER = gql`
  query viewer {
    viewer {
      company {
        isHiddenPrice
        isHiddenWhoNeedsHelp
      }
      id
    }
  }
`

export const useViewer = () =>
  useQuery(VIEWER, { fetchPolicy: 'cache-and-network' })
