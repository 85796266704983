import qs from 'qs'
import { CatalogContextSourceEnum } from '../organisms/CatalogContextProvider'
import { PT_SOURCE_QUERY_PARAM } from '../organisms/CatalogContextProvider/hooks/useQuerySave'
import { useLocation } from '@reach/router'

export const useCheckIsWizard = () => {
  const { search, pathname } = useLocation()
  const searchObj = qs.parse(search, {
    ignoreQueryPrefix: true
  })

  return (
    searchObj[PT_SOURCE_QUERY_PARAM] === CatalogContextSourceEnum.Wizard ||
    pathname.replaceAll('/', '') === 'wizard'
  )
}
