import React from 'react'
import styled from 'styled-components'
import { StepHeader } from './common/StepHeader'
import { ReactComponent as ThanksSVG } from './img/Thanks.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSVG = styled(ThanksSVG)`
  align-self: center;
  margin-top: 24px;
`

export const ThanksForScore = () => (
  <Container>
    <StepHeader title={<>Спасибо что поделились отзывом!</>} />
    <StyledSVG />
  </Container>
)
