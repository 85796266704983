/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from 'react'
import Select, { OptGroup, Option, useBaseProps } from 'rc-select'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { Bottom, RecordButton, StyledForm, Wrapper } from './index.styles'
import { Field, useFormState } from 'react-final-form'
import { Event as GAEvent } from '../../../../components/GA'
import { GuideBlock } from '../GuideBlock'
import { ReactComponent as IconFamilySVG } from '../img/family.svg'
import { ReactComponent as IconIndividualSVG } from '../img/individual.svg'
import { Icons, Text } from '../../../../../youtalk-storybook/src/ui'
import { InputCalendarField } from '../SelectTimeFormFields/InputCalendarField'
import { Language } from '../../../../atoms/mappers/gqlEnums/language'
import { Loader } from '../../../../../youtalk-storybook/src/ui/atoms/Loader'
import { OptionsCaptions, OptionsNames } from '../mappers/prices'
import { RegistrationSource } from '../../../../atoms/mappers/gqlEnums/registrationSourse'
import { SessionType } from '../../../../atoms/mappers/gqlEnums/sessionType'
import { TherapyType } from '../../../../atoms/mappers/gqlEnums/therapyType'
import { TimeSlotsField } from '../SelectTimeFormFields/TimeSlotsField'
import { YellowAlarmPlate } from '../../../../molecules/AlarmPlate'
import { dropDownCSS, optionGroupCSS, selectStyles } from './selectorStyles'
import { formatPriceRoublesWithPennies } from '../../../../atoms/formatters'
import { getCheckDateInDaysRangeCb } from '../../../../atoms/date'
import { isEmpty } from 'rambda'
import { isMobile } from '../../../../../youtalk-storybook/src/ui/atoms/isMobile'
import { useAvailableSlotsContext } from '../AvailableSlotsFormProvider'
import { useCheckIsWizard } from '../../../../hooks/useCheckIsWizard'
import { useFormattedCatalogFiltersData } from '../../../RegistrationModals/hooks/useFormattedCatalogFiltersData'
import { useGetPsychologistRatesQuery } from '../query'
import { useLoggedUsername } from '../../../../components/Header/useLoggedUsername'
import { useOpenStartCatalogRegistrationModal } from '../../useOpenStartCatalogRegistrationModal'
import { usePsyCardStorage } from '../../usePsyCardStorage'
import { useRegisterResult } from '../../../../atoms/useRegisterResult'
import { useViewer } from '../../../../queries/useViewer'

export const BottomBlock = ({ isUrgent, psychologist }) => {
  const startCatalogRegistration = useOpenStartCatalogRegistrationModal()
  const { values } = useFormState()
  const [loggedUserName] = useLoggedUsername()

  const { setRegisterResult } = useRegisterResult()

  const recordButtonProps = useMemo(() => {
    if (values.time) {
      return {
        children: `Записаться ${values.date.format('D MMM')}, ${values.time}`
      }
    }

    return {
      children: 'Записаться',
      disabled: true
    }
  }, [values])

  const handleStartRegistration = (e) => {
    GAEvent.bookWizardCalendar('plate')
    startCatalogRegistration(e)
  }

  const isWizard = useCheckIsWizard()

  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData = useFormattedCatalogFiltersData(
    storedValue,
    psychologist
  )

  const handleRecordToPsychologist = () => {
    const dateWithHours = dayjs(values.date).set(
      'hours',
      values.time.split(':')[0]
    )

    const registerResult = {
      newPsychologistId: formattedCatalogFiltersData.psychologistID,
      sessionDetails: {
        clientType:
          formattedCatalogFiltersData.clientType ?? TherapyType.Individual,
        language: formattedCatalogFiltersData.language ?? Language.Russian,
        sessionDateTime: dateWithHours,
        sessionFormat: formattedCatalogFiltersData.sessionFormat,
        isUrgent: isUrgent,
        registrationSource: isWizard
          ? RegistrationSource.Wizard
          : RegistrationSource.Catalog
      }
    }
    setRegisterResult(registerResult)
    // eslint-disable-next-line no-restricted-globals
    location.href = process.env.GATSBY_LK_URL
  }

  return (
    <>
      {isUrgent && (
        <YellowAlarmPlate
          text="Сессию нельзя будет перенести или отменить"
          visible={isUrgent}
        />
      )}
      <Bottom>
        <RecordButton
          onClick={
            loggedUserName
              ? handleRecordToPsychologist
              : handleStartRegistration
          }
          {...recordButtonProps}
        />
      </Bottom>
    </>
  )
}

const getIconForSelectOption = (type) => {
  switch (type) {
    case SessionType.Individual:
      return (
        <div>
          <IconIndividualSVG />
        </div>
      )
    case SessionType.Family:
      return (
        <div>
          <IconFamilySVG />
        </div>
      )
    default:
      return <></>
  }
}

const OptionNameAndCaption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const SelectGroupTitle = styled(({ language, className }) => (
  <div className={className}>
    <Text.Average bold>
      На {language === Language.Russian ? 'русском' : 'английском'} языке
    </Text.Average>
  </div>
))`
  padding: 10px 14px;
  height: 40px;
  color: #333333; // add to colors
`

const StyledOption = styled(({ type, price, className }) => (
  <div className={className}>
    {getIconForSelectOption(type)}
    <OptionNameAndCaption>
      <Text.Average semiBold>
        {OptionsNames[type]} •{' '}
        {formatPriceRoublesWithPennies({
          amount: price
        })}
      </Text.Average>
      <Text.Small>{OptionsCaptions[type]}</Text.Small>
    </OptionNameAndCaption>
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: 8px;

  padding: ${(props) => (props.padding ? ' 8px 14px' : null)};

  & svg {
    transition: all 0.3s ease;
  }

  & svg[open] {
    transform: rotate(180deg);
  }
  & svg {
    color: #e9f2fd;
  }
`

export const propsToValueForSelector = ({ type, language }) =>
  [type, language].join('/')

export const selectorValueToProps = (value) => (value ? value.split('/') : [])

const getStyledOption = (rate) => {
  const key = propsToValueForSelector({
    type: rate.type,
    language: rate.language
  })
  return (
    <Option key={key} value={key}>
      <StyledOption padding price={rate.price} type={rate.type} />
    </Option>
  )
}

export const getSelectOptions = (rates) => {
  const getOptionsForLanguage = (language) =>
    rates.filter((rate) => rate.language === language)

  const russianOptions = getOptionsForLanguage(Language.Russian)
  const englishOptions = getOptionsForLanguage(Language.English)

  return [
    russianOptions.length > 0 && (
      <OptGroup
        key="rus"
        className={optionGroupCSS}
        label={<SelectGroupTitle language={Language.Russian} />}
      >
        {russianOptions.map((ro) => getStyledOption(ro))}
      </OptGroup>
    ),
    englishOptions.length > 0 && (
      <OptGroup
        key="eng"
        className={optionGroupCSS}
        label={<SelectGroupTitle language={Language.English} />}
      >
        {englishOptions.map((eo) => getStyledOption(eo))}
      </OptGroup>
    )
  ]
}
const getPopupContainer = (id) => () =>
  document.getElementById(`dropdown-${id}`)

export const StyledSelect = styled(Select)`
  ${selectStyles}
`

export const tariffFieldName = 'sessionTypeAndLanguage'

const TextWithCrossIcon = styled(({ className }) => {
  const selectBaseProps = useBaseProps()

  return (
    <div className={className}>
      <Text.Large bold>Тарифы</Text.Large>
      <div onClick={() => selectBaseProps.toggleOpen()}>
        <Icons.IconCross />
      </div>
    </div>
  )
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 24px;

  ${Text.Large} {
    font-size: 18px;
    line-height: 24px;
  }
`

const StyledRender = styled(({ children, className }) => (
  <div className={className}>
    <TextWithCrossIcon />
    <div>{children}</div>
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: var(--height);
  overflow-y: scroll;
`

export const SessionTypeSelector = ({
  psychologist,
  psychologistUserId,
  popupContainerId
}) => {
  const { values } = useFormState()
  const { data, loading } = useGetPsychologistRatesQuery({
    variables: {
      id: psychologistUserId || psychologist.id
    }
  })

  const ratesToSelectFrom = useMemo(() => {
    const rates = data?.psychologistsCatalogItem.rates ?? []
    const date = Array.isArray(values.date) ? values.date[0] : values.date

    if (!date) {
      return psychologist?.rates[0]?.prices ?? []
    }

    const flexibleRate = rates.find(getCheckDateInDaysRangeCb(date))

    const relationshipRate = rates.find(
      ({ startDate, finishDate }) => !startDate && !finishDate
    )

    const currentRate = flexibleRate ?? relationshipRate

    if (isEmpty(currentRate ?? {})) {
      return []
    }

    return currentRate
  }, [data?.psychologistsCatalogItem.rates, values.date, psychologist?.rates])

  const rates = (ratesToSelectFrom?.prices ?? []).filter(
    (rate) => rate.type === 'INDIVIDUAL' || rate.type === 'FAMILY'
  )
  const selectOptions = getSelectOptions(rates)

  const id = 'styled-select'
  const mobileProps = isMobile
    ? {
        getPopupContainer: getPopupContainer(popupContainerId),
        dropdownRender: (menu) => <StyledRender>{menu}</StyledRender>,
        virtual: false
      }
    : {}

  return (
    <>
      <Field name={tariffFieldName}>
        {({ input }) =>
          loading ? (
            <Loader />
          ) : (
            <>
              {rates.length > 1 && (
                <StyledSelect
                  getPopupContainer={getPopupContainer(id)}
                  id={id}
                  suffixIcon={<Icons.IconArrowDown />}
                  {...input}
                  {...mobileProps}
                >
                  {selectOptions}
                </StyledSelect>
              )}

              {rates.length === 1 && (
                <StyledOption price={rates[0].price} type={rates[0].type} />
              )}

              <div css={dropDownCSS} id={`dropdown-${id}`}></div>
            </>
          )
        }
      </Field>
    </>
  )
}

export const SelectTimeForm = ({
  handleSubmit,
  values,
  psychologist,
  psychologistUserId,
  isModal
}) => {
  const { psychologistAvailableSlots, loadingSlots } =
    useAvailableSlotsContext()

  const [isUrgent, setIsUrgent] = useState(false)

  const { data, loading } = useViewer()

  return (
    <StyledForm isModal={isModal} onSubmit={handleSubmit}>
      <Wrapper isModal={isModal} loading={loadingSlots}>
        {loadingSlots || loading ? (
          <Loader />
        ) : (
          <>
            <Text.Large bold>Запись на сессию</Text.Large>
            {!data?.viewer?.company?.isHiddenWhoNeedsHelp && (
              <SessionTypeSelector
                psychologist={psychologist}
                psychologistUserId={psychologistUserId}
              />
            )}
            <InputCalendarField
              psychologistAvailableSlots={psychologistAvailableSlots}
              updateIsUrgent={setIsUrgent}
            />
            {values.date ? (
              <TimeSlotsField
                loadingSlots={loadingSlots}
                psychologistAvailableSlots={psychologistAvailableSlots}
                updateIsUrgent={setIsUrgent}
              />
            ) : null}
            <BottomBlock isUrgent={isUrgent} psychologist={psychologist} />
          </>
        )}
      </Wrapper>
      <GuideBlock />
    </StyledForm>
  )
}
