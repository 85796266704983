import React from 'react'
import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 16px;

  @media (max-width: ${size.sm}) {
    padding-right: 32px;
  }
`

const StyledDescription = styled.p`
  max-width: 100%;

  @media (max-width: ${size.sm}) {
    max-width: 70%;
  }
`

export const StepHeader = ({ title, description }) => (
  <Header>
    <Title.H4>{title}</Title.H4>
    <StyledDescription>{description}</StyledDescription>
  </Header>
)
