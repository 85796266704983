import { gql, useMutation } from '@apollo/client'

const ADD_PSYCHOLOGIST_PROFILE_RATING = gql`
  mutation ratePsychologistProfile($input: RatePsychologistProfileInput!) {
    ratePsychologistProfile(input: $input) {
      status
    }
  }
`

export const useAddPsychologistProfileRatingMutation = (input) =>
  useMutation(ADD_PSYCHOLOGIST_PROFILE_RATING, input)
